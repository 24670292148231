import { Timeline } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { TimelineBlock } from '../Types/Data';
import { useState } from 'react';
import WrapperScrollAnimation from './WrapperScrollAnimation';
import 'animate.css';

interface TimelineBlockProps {
    data: TimelineBlock[];
}

export default ({ data }: TimelineBlockProps) => {
    const [areVisibleBlocks, setAreVisibleBlocks] = useState<number[]>([]);
    const [triggerAnimations, setTriggerAnimations] = useState<number[]>([]);

    const toggleVisibleBlock = (index: number) => {
        if (!areVisibleBlocks.includes(index)) {
            setTriggerAnimations((prev) => [...prev, index]);
        }
        setAreVisibleBlocks((prevState) =>
            prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index],
        );
    };

    return (
        <WrapperScrollAnimation>
            <Timeline
                items={[
                    ...data.map(({ title, subtitle, date, seeMore }: TimelineBlock, index) => ({
                        children: (
                            <div
                                className="flex flex-col text-left w-full"
                                key={uuidv4()}
                            >
                                <div className="text-2xl font-bold text-[#4d4d4d]">{title}</div>
                                <div className="text-xl font-bold text-[#6f6f6f]">{subtitle}</div>
                                <div className="text-lg font-normal text-[#6f6f6f]">{date}</div>
                                {seeMore && areVisibleBlocks.includes(index) && (
                                    <div
                                        className={`see-more text-lg text-[#6f6f6f] 2xl:pr-[50px] ${
                                            triggerAnimations.includes(index) ? 'animate__animated animate__fadeIn' : ''
                                        }`}
                                        onAnimationEnd={() =>
                                            setTriggerAnimations((prev) => prev.filter((i) => i !== index))
                                        }
                                        dangerouslySetInnerHTML={{ __html: seeMore }}
                                    />
                                )}
                                {seeMore && !areVisibleBlocks.includes(index) && (
                                    <div
                                        className="text-sm text-[#6f6f6f] cursor-pointer underline"
                                        onClick={() => toggleVisibleBlock(index)}
                                    >
                                        Voir plus
                                    </div>
                                )}
                            </div>
                        ),
                        color: 'gray',
                    })),
                    { children: undefined, dot: <></> },
                ]}
            />
        </WrapperScrollAnimation>
    );
};
