import { Image } from 'antd';
import { Technology } from '../Types/Data';
import WrapperScrollAnimation from './WrapperScrollAnimation';

interface TechnologiesBlockProps {
    data: Technology[];
}

export default ({ data }: TechnologiesBlockProps) => (
    <div
        className="
            grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-2
            w-full gap-y-20 gap-8 mx-auto
        "
    >
        {data.map(({ name, img, link }) => (
            <WrapperScrollAnimation>
                <div
                    key={name}
                    className="flex flex-col gap-[10px] items-center"
                >
                    <div className="text-lg">{name}</div>
                    <a href={link}>
                        <Image
                            preview={false}
                            src={process.env.REACT_APP_BACK_URL + img}
                            alt=""
                            width={128}
                            height={128}
                        />
                    </a>
                </div>
            </WrapperScrollAnimation>
        ))}
    </div>
);
