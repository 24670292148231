import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FullPageImage from '../../Components/FullPageImage/FullPageImage';
import Loader from '../../Components/Loader/Loader';
import Banner from '../../Components/Banner';
import TechnologiesBlock from '../../Components/TechnologiesBlock';
import TimelineBlock from '../../Components/TimelineBlock';
import WrapperScrollAnimation from '../../Components/WrapperScrollAnimation';
import { Data } from '../../Types/Data';
import './Home.css';
import 'animate.css/animate.compat.css';

export default () => {
    const [data, setData] = useState<Data | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async () => {
        const response = await fetch(process.env.REACT_APP_BACK_URL + '/cv');
        const res = await response.json();
        setData(res);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    if (isLoading) {
        return <Loader />;
    }

    const { hero, banner, technologies, timelineEducation, timelineExperiences, projects } = data;

    return (
        <div className="h-screen">
            <FullPageImage
                title={hero.title}
                image={process.env.REACT_APP_BACK_URL + hero.img}
            />
            <Banner color="light">
                <WrapperScrollAnimation animateIn='slideInRight'>
                    <div className="main-title">{banner.title}</div>
                </WrapperScrollAnimation>
                <WrapperScrollAnimation animateIn='slideInRight'>
                    <div
                        className="main-description w-full"
                        dangerouslySetInnerHTML={{ __html: banner.description }}
                    />
                </WrapperScrollAnimation>
            </Banner>
            <Banner color="dark">
                <div className="main-title">{technologies.title}</div>
                <div className="main-description">
                    <TechnologiesBlock data={technologies.items} />
                </div>
            </Banner>
            <Banner color="light">
                <div className="grid 2xl:grid-cols-2 grid-cols-1 w-full m-auto">
                    <div className="flex flex-col gap-10 items-start">
                        <div className="tracking-[2px] font-medium text-3xl mx-auto text-center">
                            {timelineExperiences.title}
                        </div>
                        <TimelineBlock data={timelineExperiences.items} />
                    </div>
                    <div className="flex flex-col gap-10 items-start">
                        <div className="tracking-[2px] font-medium text-3xl mx-auto text-center">
                            {timelineEducation.title}
                        </div>
                        <TimelineBlock data={timelineEducation.items} />
                    </div>
                </div>
            </Banner>
            <Banner color="dark">
                <div className="main-title">{projects.title}</div>
                <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 w-full gap-20 mx-auto">
                    {data.projects.items.map(({ name, img, link }) => (
                        <WrapperScrollAnimation>
                            <div
                                key={name}
                                className="flex flex-col gap-[10px] items-center project"
                            >
                                <div className="text-lg">{name}</div>
                                <Link
                                    key={uuidv4()}
                                    className="project"
                                    to={link}
                                >
                                    <Image
                                        preview={false}
                                        src={process.env.REACT_APP_BACK_URL + img}
                                        alt=""
                                        width={256}
                                        height={256}
                                    />
                                </Link>
                            </div>
                        </WrapperScrollAnimation>
                    ))}
                </div>
            </Banner>
        </div>
    );
};
