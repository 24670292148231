import { useState, useEffect } from 'react';
import { Checkbox, ConfigProvider, Select, Spin, theme } from 'antd';
import Card from './Card';

export interface Event {
    from: string;
    date: string;
    link: string;
    location: string;
    name: string;
    slots: number;
    type: string;
}

interface Filter {
    type: string[];
    location: string[];
    ignoreFull: boolean;
}

export default () => {
    const LOCAL_STORAGE_KEY = 'filtersCaverne';
    const [events, setEvents] = useState<Event[] | undefined>(undefined);
    const [baseEvents, setBaseEvents] = useState<Event[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [locations, setLocations] = useState<string[]>([]);
    const [filters, setFilters] = useState<Filter | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getEvents = async () => {
        const response = await fetch(process.env.REACT_APP_BACK_URL + '/tournaments');
        const res = await response.json();
        setBaseEvents(res);
        const uniqueTypes: string[] = Array.from(new Set(res.map((event: Event) => event.type)));
        const uniqueLocations: string[] = Array.from(new Set(res.map((event: Event) => event.location)));
        setTypes(uniqueTypes);
        setLocations(uniqueLocations);
    };

    useEffect(() => {
        getEvents();
        document.body.classList.add('bg-[#242424]');
    }, []);

    useEffect(() => {
        if (filters) {
            const newEvents = baseEvents.filter((item) => {
                if (filters?.ignoreFull && item.slots === 0) {
                    return false;
                }

                const typeIsValid = !filters?.type?.length || filters.type.includes(item.type);
                const locationIsValid = !filters?.location?.length || filters.location.includes(item.location);

                return typeIsValid && locationIsValid;
            });
            setEvents(newEvents);
        }
    }, [filters]);

    useEffect(() => {
        if (baseEvents.length > 0) {
            const localStorageFilters = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (localStorageFilters) {
                const jsonLocalStorageFilters = JSON.parse(localStorageFilters);
                if (areFiltersValid(jsonLocalStorageFilters)) {
                    setFilters(jsonLocalStorageFilters);
                } else {
                    localStorage.removeItem(LOCAL_STORAGE_KEY);
                }
            }
            setEvents(baseEvents);
        }
    }, [baseEvents]);

    useEffect(() => {
        if (events && events.length > 0) {
            setIsLoading(false);
        }
    }, [events]);

    const areFiltersValid = (filters: Filter) => {
        const availableTypes = baseEvents.map((item) => item.type);
        const availableLocations = baseEvents.map((item) => item.location);

        if (filters?.type && filters.type.length > 0) {
            if (!filters.type.every((type) => availableTypes.includes(type))) {
                return false;
            }
        }

        if (filters?.location && filters.location.length > 0) {
            if (!filters.location.every((location) => availableLocations.includes(location))) {
                return false;
            }
        }

        return true;
    };

    const addFilter = (propriety: string, value: string[] | boolean) => {
        const newFilters = { ...filters, [propriety]: value };
        setFilters(newFilters);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newFilters));
    };

    return (
        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <div className="flex flex-col">
                <div className="flex h-[50px] md:h-[65px] w-full border-b border-[#a8b3cf] border-opacity-80 text-white justify-center items-center">
                    Le site de la caverne mais en mieux
                </div>
                {isLoading ? (
                    <Spin
                        className="mt-4"
                        size="large"
                    />
                ) : (
                    <>
                        <div className="flex w-full px-5 mt-4 mx-auto flex-col md:flex-row justify-center items-center text-black gap-5">
                            <Select
                                defaultValue={filters?.type}
                                className="w-full md:w-auto md:min-w-[200px]"
                                mode="multiple"
                                allowClear
                                onChange={(e) => addFilter('type', e)}
                                placeholder="Jeu"
                                options={types.map((item) => ({ label: item, value: item }))}
                            />
                            <Select
                                defaultValue={filters?.location}
                                className="w-full md:w-auto md:min-w-[200px]"
                                mode="multiple"
                                allowClear
                                onChange={(e) => addFilter('location', e)}
                                placeholder="Lieu"
                                options={locations.map((item) => ({ selected: true, label: item, value: item }))}
                            />
                            <Checkbox
                                defaultChecked={filters?.ignoreFull}
                                onChange={(e) => addFilter('ignoreFull', e.target.checked)}
                            >
                                Ignore if full
                            </Checkbox>
                        </div>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[20px] p-5">
                            {events.length !== 0 &&
                                events.map((item) => (
                                    <div
                                        key={item.link}
                                        className="flex"
                                    >
                                        <Card
                                            key={item.link}
                                            item={item}
                                        />
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
        </ConfigProvider>
    );
};
