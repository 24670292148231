const generatedHues: { name: string, hue: number}[] = [];

const generateDistinctHueFromString = (str: string, minDistance: number) => {
    const hash = str.split('').reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0);
    let hue = Math.abs(hash) % 360;

    for (const existingHue of generatedHues) {
        while (Math.abs(hue - existingHue.hue) < minDistance) {
            hue = (hue + minDistance) % 360;
        }
    }

    generatedHues.push({ name: str, hue });
    return hue;
};

export function stringToHexColor(str: string) {
    const saturation = 50;
    const lightness = 80;

    const found = generatedHues.find(item => item.name === str);

    if (found) {
        return hslToHex(found.hue, saturation, lightness);
    }

    const hue = generateDistinctHueFromString(str, 30);
    return hslToHex(hue, saturation, lightness);
}

// https://stackoverflow.com/a/44134328
const hslToHex = (h: number, s: number, l: number) => {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
