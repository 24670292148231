import './FullPageImage.css';

interface FullPageImageProps {
    title?: string;
    image?: string;
}

export default ({ title, image }: FullPageImageProps) => {
    return (
        <div
            className="full-page-image-container"
            style={{ backgroundImage: `url(${image})` }}
        >
            {title && (
                <div className="full-page-image-caption">
                    <span className="full-page-image-span">{title}</span>
                </div>
            )}
        </div>
    );
};
