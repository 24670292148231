import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from '../Pages/Home/Home';
import Roguelike from '../Projects/Roguelike';
import ScraperCaverne from '../Projects/ScraperCaverne';

export default () => (
    <Router>
        <Routes>
            <Route
                path="/"
                element={<Home />}
            />
            <Route
                path="/roguelike"
                element={<Roguelike />}
            />
            <Route
                path="/caverne"
                element={<ScraperCaverne />}
            />
        </Routes>
    </Router>
);
