interface BannerProps {
    color: 'light' | 'dark';
    children?: React.ReactNode;
    className?: string;
}

export default ({ color, children, className }: BannerProps) => {
    const colorVariants = {
        light: 'text-[#777] bg-[#fff]',
        dark: 'text-[#ddd] bg-[#282E34]',
    };

    return (
        <div
            className={`
            py-[50px] md:px-[80px] px-[30px]
            ${colorVariants[color]} 
            ${className ?? ''}
        `}
        >
            <div
                className="
                    flex flex-col text-justify
                    2xl:max-w-[1920px] max-w-[1280px] mx-auto w-full
                    gap-8
                "
            >
                {children}
            </div>
        </div>
    );
};
