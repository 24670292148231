import { contrastColor } from 'contrast-color';
import type { Event } from './index';
import { stringToHexColor } from '../../Utils/StringToColor';
import { Card, ConfigProvider } from "antd";
import chair from '../../Components/SVG/chair.svg';
import './Card.css';

interface CardProps {
    item: Event;
}

export default ({ item }: CardProps) => {
    const { name, date, slots, type, location, link } = item;
    const backgroundColor = stringToHexColor(type);
    const foregroundColor = contrastColor({ bgColor: backgroundColor });

    return (
        <ConfigProvider
            theme={{
                components: {
                    Card: {
                        headerBg: backgroundColor,
                        colorTextHeading: foregroundColor,
                        extraColor: foregroundColor,
                    }
                }
            }}
        >
            <a href={link} className='w-full h-full'>
                <Card className='card-tournament flex flex-col h-full' title={type} extra={location.toUpperCase()}>
                    <div className='flex flex-col w-full items-center justify-between gap-3'>
                        <div className='font-bold text-lg flex text-center'>{name}</div>
                        <div className='flex justify-between w-full'>
                            <div className='flex'>
                                <img className='size-[22px]' src={chair} alt="chair" />
                                {slots}
                            </div>
                            <div>{date}</div>
                        </div>
                    </div>
                </Card>
            </a>
        </ConfigProvider>
    );
};
