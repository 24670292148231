import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleProvider } from '@ant-design/cssinjs';
import AppRouter from './AppRouter/AppRouter';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <StyleProvider>
        <AppRouter />
    </StyleProvider>,
);
